export const bookingSteps = Object.freeze({
  CHOOSE_AGENDA: "choose_agenda",
  CHOOSE_SERVICE: "choose_service",
  SELECT_MULTIPLE_SERVICES: "select_multiple_services",
  CHOOSE_SUBSCRIPTION: "choose_subscription",
  CHOOSE_DATE: "choose_date",
  CHOOSE_HOUR: "choose_hour",
  COMPLETE_FORM: "complete_form",
  THANK_YOU: "thank_you",
});

export const bookingTypes = Object.freeze({
  BOOK_SERVICES: "book_services",
  BOOK_SUBSCRIPTIONS: "book_subscriptions",
});
