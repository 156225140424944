export const routes = [
  {
    path: "/",
    name: "route.companyWebsite",
    component: async function () {
      return await import("@/vue/views/company-website/CompanyWebsite.vue");
    },
    children: [
      {
        path: "servicii",
        name: "route.companyWebsite.services",
        component: async function () {
          return await import("@/vue/views/company-website/CompanyWebsite.vue");
        },
        meta: {
          requiresService: true,
        },
      },
      {
        path: "servicii/:agendaSlug",
        name: "route.companyWebsite.services.agendas",
        component: async function () {
          return await import("@/vue/views/company-website/CompanyWebsite.vue");
        },
        meta: {
          requiresService: true,
        },
      },
      {
        path: "servicii/:agendaSlug/:eventTypeSlug",
        name: "route.companyWebsite.services.agendas.eventTypes",
        component: async function () {
          return await import("@/vue/views/company-website/CompanyWebsite.vue");
        },
        meta: {
          requiresService: true,
        },
      },
      {
        path: "abonamente",
        name: "route.companyWebsite.subscriptions",
        component: async function () {
          return await import("@/vue/views/company-website/CompanyWebsite.vue");
        },
        meta: {
          requiresSubscriptions: true,
        },
      },
      {
        path: "abonamente/:subscriptionTypeSlug",
        name: "route.companyWebsite.subscriptions.subscriptionType",
        component: async function () {
          return await import("@/vue/views/company-website/CompanyWebsite.vue");
        },
        meta: {
          requiresSubscriptions: true,
        },
      },
    ],
  },
  {
    path: "/termeni-si-conditii",
    name: "route.companyWebsite.termsAndConditions",
    component: async function () {
      return await import("@/vue/views/company-website/TermsAndConditionsView.vue");
    },
  },
  {
    path: "/contact",
    name: "route.companyWebsite.contact",
    component: async function () {
      return await import("@/vue/views/company-website/ContactUsView.vue");
    },
  },
  {
    path: "/schedule-embedded",
    name: "route.companyWebsite.scheduleEmbedded",
    component: async function () {
      return await import("@/vue/views/company-website/ScheduleEmbedded.vue");
    },
  },
  {
    path: "/plata-serviciu",
    name: "route.companyWebsite.servicePayment",
    component: async function () {
      return await import("@/vue/views/company-website/ServicePaymentView.vue");
    },
  },

  // TODO: agenda in iFrame
  // aceasta ruta va folosita doar pentru iFrame
  {
    path: "/agenda/:agendaSlug",
    name: "route.publicAgendas",
    // NOTE: temporary redirect until iFrame is done
    redirect: () => {
      return { path: "/" };
    },
    // component: async function () {
    //   return await import("@/vue/views/public-agendas/PublicAgendas.vue");
    // },
    // children: [
    //   {
    //     path: "",
    //     name: "route.publicAgendas.eventTypes", // ???: ..
    //     component: async function () {
    //       return await import("@/vue/views/public-agendas/EventTypesView.vue");
    //     },
    //   },
    //   {
    //     path: ":eventTypeSlug",
    //     name: "route.publicAgendas.booking", // ???: ..
    //     component: async function () {
    //       return await import("@/vue/views/public-agendas/BookingView.vue");
    //     },
    //   },
    // ],
  },
  {
    path: "/formular/covid/:type/:formUrlId",
    name: "route.forms.covid",
    component: async function () {
      return await import("@/vue/views/forms/CovidForm.vue");
    },
  },
  {
    path: "/formular/gdpr/:type/:formUrlId",
    name: "route.forms.gdpr",
    component: async function () {
      return await import("@/vue/views/forms/GdprForm.vue");
    },
  },
  {
    path: "/formular/cerere-complex-bucuresti/:type/:formUrlId",
    name: "route.forms.cerere-complex-bucuresti",
    component: async function () {
      return await import("@/vue/views/forms/CerereComplexBucurestiForm.vue");
    },
  },
  {
    path: "/formular/gdpr-living-art-studio/:type/:formUrlId",
    name: "route.forms.gdpr-living-art-studio",
    component: async function () {
      return await import("@/vue/views/forms/GdprLivingArtStudio.vue");
    },
  },
  {
    path: "/formular/contract-living-art-studio/:type/:formUrlId",
    name: "route.forms.contract-living-art-studio",
    component: async function () {
      return await import("@/vue/views/forms/ContractLivingArtStudio.vue");
    },
  },

  {
    path: "/planificator/:hash",
    name: "route.userPlanner",
    component: async function () {
      return await import("@/vue/views/my-settings/UserPlanner.vue");
    },
  },
  {
    path: "/planificator/login/:hash",
    name: "route.planner.login",
    component: async function () {
      return await import("@/vue/views/my-settings/PlannerLogin.vue");
    },
  },

  {
    /**
     * Match all paths
     * uses /:pathMatch(.*)* or /:pathMatch(.*) or /:catchAll(.*)
     */
    path: "/:catchAll(.*)",
    redirect: { name: "route.notFound", replace: false },
  },
  {
    path: "/404",
    name: "route.notFound",
    component: async function () {
      return await import("@/vue/views/NotFound.vue");
    },
  },
];
