import { defineStore } from "pinia";

import { bookingSteps, bookingTypes } from "@/constants/booking.js";
import { setMomentDefaultTimezone } from "@/utils/dates";
import { MULTIPLE_SERVICES_EVENT_COLOR } from "@/constants/general.js";

export const usePublicWebsiteStore = defineStore("companyWebsite", {
  state: () => {
    return {
      isInitiated: false,

      isEnabledPublicWebsiteAccess: false,
      isEnabledPublicWebsiteEmbeddedAccess: false,
      isEnabledCategories: false,
      enabledEventTypeParticipantsPerOption: false,
      isEnabledMultipleServicesPerEvent: false,
      isEnabledInvoiceProvider: false,

      bookingType: null,
      bookingStep: null,
      settingsData: [],
      companyTimezone: null,
      companySettingId: null,
      agendas: [],
      agendaCategories: [],
      subscriptionsTypes: [],
      selectedAgendaSlug: null,
      selectedAgendaOptions: null,
      selectedEventType: null,
      selectedEventTypes: [],
      safeColor: null,
      selectedSpot: null,
      numberOfParticipants: null,
      bookedInfo: null,
      shouldAllowPaymentForm: false,
      shouldAllowRecurrentPayments: false,
      addressDetails: null,
      selectedBookingHasEnabledRecurrentPayment: false,
      subscriptionDescription: null,
    };
  },
  getters: {
    hasAgendas: state => {
      if (state.agendas && state.agendas.length > 0) return true;
      return false;
    },
    hasMoreThanOneAgenda: state => {
      if (state.agendas && state.agendas.length > 1) return true;
      return false;
    },
    hasSubscriptions: state => {
      if (state.subscriptionsTypes && state.subscriptionsTypes.length > 0) return true;
      return false;
    },
    hasAgendasAndSubscriptions: state => {
      if (
        state.agendas &&
        state.agendas.length > 0 &&
        state.subscriptionsTypes &&
        state.subscriptionsTypes.length > 0
      )
        return true;
      return false;
    },
    getCompanyInfoFirst: state => type => {
      if (state.settingsData) return state.settingsData.find(element => element.type === type);
      return null;
    },
    getCompanyInfo: state => type => {
      if (state.settingsData) return state.settingsData.filter(element => element.type === type);
      return null;
    },
    getSelectedAgenda: state =>
      state.agendas.find(agenda => agenda.slug === state.selectedAgendaSlug),
    getSelectedAgendaOptions: state => option => {
      if (state.selectedAgendaOptions && option in state.selectedAgendaOptions)
        return state.selectedAgendaOptions[option];
      return null;
    },
    getShouldAllowPaymentForm: state => state.shouldAllowPaymentForm,
    getShouldAllowRecurrentPayments: state => state.shouldAllowRecurrentPayments,
    getTimezoneValue() {
      return this.companyTimezone;
    },
    getSelectedBookingHasEnabledRecurrentPayment: state =>
      state.selectedBookingHasEnabledRecurrentPayment,

    totalMultipleServicesPrice: state => {
      let price = 0;
      for (const selectedEventType of state.selectedEventTypes) {
        price += selectedEventType.price;
      }
      return price;
    },
    totalMultipleServicesDuration: state => {
      let duration = 0;
      for (const selectedEventType of state.selectedEventTypes) {
        duration += selectedEventType.duration;
      }
      return duration;
    },
    enabledServicesPrice: state => {
      if (state.selectedEventType) {
        return state.selectedEventType.enabledPrice;
      } else if (state.selectedEventTypes?.length > 0) {
        return !!state.selectedEventTypes.find(eventType => eventType.enabledPrice);
      }
      return false;
    },
    servicesPrice(state) {
      if (state.selectedEventType) {
        return state.selectedEventType.price;
      } else if (state.selectedEventTypes?.length > 0) {
        return this.totalMultipleServicesPrice;
      }
      return 0;
    },
    usedServicesColor: state => {
      if (state.selectedEventType?.color) return state.selectedEventType.color;
      else if (state.selectedEventTypes?.length > 0) return MULTIPLE_SERVICES_EVENT_COLOR;
      return "#000";
    },
  },
  actions: {
    initBooking() {
      if (this.hasAgendas || this.hasAgendasAndSubscriptions)
        if (this.agendas.length === 1) {
          // NOTE: BOOK_SERVICES by default if there is at least 1 agenda
          this.selectedAgendaSlug = this.agendas[0].slug;
          this.bookingStep = bookingSteps.CHOOSE_SERVICE;
          this.bookingType = bookingTypes.BOOK_SERVICES;
        } else if (this.agendas.length > 1) {
          // NOTE: BOOK_SERVICES and choose AGENDA if more agendas
          this.bookingStep = bookingSteps.CHOOSE_AGENDA;
          this.bookingType = bookingTypes.BOOK_SERVICES;
        }

      if (this.hasSubscriptions || this.hasAgendasAndSubscriptions)
        if (this.subscriptionsTypes.length >= 1 && this.agendas.length === 0) {
          // NOTE: BOOK_SUBSCRIPTIONS by default if not agendas and at least 1 subscription
          this.bookingType = bookingTypes.BOOK_SUBSCRIPTIONS;
          this.bookingStep = bookingSteps.CHOOSE_SUBSCRIPTION;
        }
    },
    setup(publicWebsiteData) {
      this.isInitiated = true;
      this.isEnabledPublicWebsiteAccess = publicWebsiteData.isEnabledPublicWebsiteAccess;
      this.isEnabledPublicWebsiteEmbeddedAccess =
        publicWebsiteData.isEnabledPublicWebsiteEmbeddedAccess;
      this.isEnabledCategories = publicWebsiteData.isEnabledCategories;
      this.enabledEventTypeParticipantsPerOption =
        publicWebsiteData.enabledEventTypeParticipantsPerOption;
      this.isEnabledMultipleServicesPerEvent = publicWebsiteData.isEnabledMultipleServicesPerEvent;
      this.isEnabledInvoiceProvider = publicWebsiteData.isEnabledInvoiceProvider;

      this.settingsData = publicWebsiteData.companySettingValue;
      this.companySettingId = publicWebsiteData.companySettingId;
      this.agendas = publicWebsiteData.agendas;
      this.agendaCategories = publicWebsiteData.agendaCategories;
      this.subscriptionsTypes = publicWebsiteData.subscriptionsTypes;
      this.shouldAllowPaymentForm = publicWebsiteData.shouldAllowPaymentForm;
      this.shouldAllowRecurrentPayments = publicWebsiteData.shouldAllowRecurrentPayments;
      this.addressDetails = publicWebsiteData.addressDetails;

      this.companyTimezone = publicWebsiteData.companySettingTimezone;
      setMomentDefaultTimezone(this.companyTimezone);

      this.subscriptionDescription = publicWebsiteData.subscriptionDescription;

      this.initBooking();
    },
    resetStateForNewBooking() {
      this.initBooking();
      if (this.agendas.length > 1) {
        this.selectedAgendaSlug = null;
      }
      this.selectedAgendaOptions = null;
      this.selectedEventType = null;
      this.selectedEventTypes = [];
      this.safeColor = null;
      this.selectedSpot = null;
      this.numberOfParticipants = null;
      this.bookedInfo = null;
      this.selectedBookingHasEnabledRecurrentPayment = false;
    },
  },
});
